<template>
  <div class="conteudo-interno">
    <div class="nova-comunicacao-container">
      <h1>Sac</h1>
      <form @submit.prevent="pesquisaForm()" class="formulario-container">
        <div class="d-md-flex">
          <div class="formulario-item f-15">
            <label class="formulario-item-label">Abertura</label>
            <datepicker :language="ptBR" v-model="form.dataAbertura" />
          </div>
          <div class="formulario-item f-15">
            <label class="formulario-item-label">Fechamento</label>
            <datepicker :language="ptBR" v-model="form.dataFechamento" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Mensagem</label>
            <input type="text" v-model="form.mensagem" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Protocolo</label>
            <input type="text" v-model="form.protocolo" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Meio de contato</label>
            <multiselect v-model="form.meioContatoId" label="descricao" track-by="id" :options="meioDeContato"
              :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" :allow-empty="true" selected-label="Selecionado" />
          </div>
        </div>
        <div class="d-md-flex">
          <div class="formulario-item">
            <label class="formulario-item-label">CPF</label>
            <input type="text" v-mask="'###.###.###-##'" v-model="form.cpf" placeholder="CPF" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Assunto</label>
            <multiselect v-model="form.assuntoId" label="descricao" track-by="id" :options="assunto" :searchable="false"
              :multiple="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover"
              :allow-empty="true" selected-label="Selecionado" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Status</label>
            <multiselect v-model="form.statusId" label="descricao" track-by="id" :options="status" :searchable="false"
              :multiple="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover"
              :allow-empty="true" selected-label="Selecionado" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Crítico</label>
            <multiselect v-model="form.critico" label="descricao" track-by="value" :options="opcoes.critico"
              :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" :allow-empty="true" selected-label="Selecionado" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Destinatário</label>
            <multiselect v-model="form.ultimaInteracaoId" label="descricao" track-by="id" :options="destinatario"
              :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" :allow-empty="true" selected-label="Selecionado" />
          </div>
        </div>
        <div class="container-btns">
          <button type="button" class="btn btn-cancelar" @click="limparFiltro">
            Limpar
          </button>
          <button type="submit" class="btn btn-laranja">Filtrar</button>
        </div>
      </form>

      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados</p>
      </div>

      <Tabela v-else :colunas="colunas" :linhas="tabelaOcorrencia" :paginacao="true" :pagina="pagina"
        :totalPaginas="totalPaginas" :funcProximaPag="proximaPag" :funcAnteriorPag="anteriorPag" />

    </div>
  </div>
</template>

<script>
import { listarSacOcorrencias, listarSacMeioDeContato, listarSacStatus, listarSacAssunto, listarSacMensagemDestinatario } from "@/services/sac"
import { functions, objects, masks } from '@/assets/js'
import { ptBR } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import opcoes from "@/utils/json/opcaoSelect"
import Tabela from "@/components/Tabela/Tabela.vue";

export default {
  name: "Sac",

  components: {
    Tabela,
    Multiselect,
    Datepicker,
  },

  data() {
    return {
      colunas: [
        { key: "status", titulo: 'Status', tamanho: 'medio-150', link: false },
        { key: "nome", titulo: 'Nome', tamanho: 'grande', link: false },
        { key: "cpf", titulo: 'CPF', tamanho: 'medio-130', link: false },
        { key: "assunto", titulo: 'Assunto', tamanho: 'grande', link: false },
        { key: "abertura", titulo: 'Abertura', tamanho: 'medio-130', link: false },
        { key: "ultimaInteracao", titulo: 'Destinatário', tamanho: 'grande', link: false },
        { key: "", titulo: '', tamanho: 'btns', link: true, func: this.detalhesOcorrencia },
      ],
      opcoes: opcoes,
      ptBR: ptBR,
      semDados: false,
      tabelaOcorrencia: [],
      pagina: 1,
      qtdPorPagina: 10,
      totalPaginas: 0,
      filtro: {},
      form: {
        statusId: [],
        assuntoId: [],
        ultimaInteracaoId: [],
        critico: [],
        cpf: '',
        protocolo: "",
        mensagem: "",
        dataAbertura: null,
        dataFechamento: null,
        meioContatoId: []
      },
      status: [],
      assunto: [],
      meioDeContato: [],
      destinatario: []
    };
  },

  created() {
    this.popularSacOcorrencias();
    this.popularAssuntoMultiselect();
    this.popularDestinatarioMultiselect();
    this.popularStatusMultiselect();
    this.popularMeiosDeContatos();
  },

  methods: {
    popularDestinatarioMultiselect() {
      listarSacMensagemDestinatario().then(resp => {
        this.destinatario = resp.data
      })
    },
    popularStatusMultiselect() {
      listarSacStatus().then(resp => {
        this.status = resp.data
      })
    },
    popularAssuntoMultiselect() {
      listarSacAssunto().then(resp => {
        this.assunto = resp.data
      })
    },
    popularMeiosDeContatos() {
      listarSacMeioDeContato().then(resp => {
        this.meioDeContato = resp.data
      })
    },
    detalhesOcorrencia(ocorrenciaId) {
      return `/sac/ocorrencia/${ocorrenciaId}`
    },
    pesquisaForm() { // Funçao para evitar requisiçao desnecessária
      if (objects.objetosIguais(this.filtro, this.tratamentoDadosForm())) { // caso todos os campos sao iguais
        return this.$store.commit("SET_MODAL", {
          ativado: true,
          mensagem: 'Nenhum campo foi alterado. Terá que mudar algum filtro para fazer uma nova busca',
          tipo: "erro",
        })
      }
      this.pagina = 1
      this.popularSacOcorrencias()
    },
    tratamentoDadosForm() {
      const data = {
        statusId: functions.verificarDadoValido(this.form.statusId) ? this.form.statusId.id : 0, //arr
        assuntoId: functions.verificarDadoValido(this.form.assuntoId) ? this.form.assuntoId.id : 0, //arr
        ultimaInteracaoId: functions.verificarDadoValido(this.form.ultimaInteracaoId) ? this.form.ultimaInteracaoId.id : 0, //arr
        critico: functions.verificarDadoValido(this.form.critico) ? this.form.critico.value : null, //arr
        meioContatoId: functions.verificarDadoValido(this.form.meioContatoId) ? this.form.meioContatoId.id : 0, //arr
        cpf: this.form.cpf.replace(/[^\d]+/g, ""),
        protocolo: this.form.protocolo,
        mensagem: this.form.mensagem,
        dataAbertura: functions.verificarDadoValido(this.form.dataAbertura) ? masks.formatarData(this.form.dataAbertura, 'YYYY-MM-DD') : "",
        dataFechamento: functions.verificarDadoValido(this.form.dataFechamento) ? masks.formatarData(this.form.dataFechamento, 'YYYY-MM-DD') : "",
        pagina: this.pagina,
        qtdPagina: this.qtdPorPagina
      }
      return data
    },
    popularSacOcorrencias() {
      listarSacOcorrencias(this.tratamentoDadosForm()).then(resp => {
        this.filtro = this.tratamentoDadosForm()
        if (resp.data.listaOcorrencias.length) {
          this.semDados = false;
          this.tabelaOcorrencia = resp.data.listaOcorrencias;
          this.totalPaginas = resp.data.paginacao.totalPaginas
          return;
        }
        this.semDados = true
      })
    },

    proximaPag() {
      this.pagina++;
      this.popularSacOcorrencias();
    },

    anteriorPag() {
      this.pagina--;
      this.popularSacOcorrencias();
    },

    limparFiltro() {
      this.form = {
        statusId: [],
        assuntoId: [],
        ultimaInteracaoId: [],
        critico: [],
        meioContatoId: [],
        cpf: "",
        protocolo: "",
        mensagem: "",
        dataAbertura: "",
        dataFechamento: "",
      };
    },

  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>